import InvalidExtensionException from "../Exceptions/ValidationException/InvalidExtensionException";
import InvalidSizeException from "../Exceptions/ValidationException/InvalidSizeException";
import InvalidNameException from "../Exceptions/ValidationException/InvalidNameException";
import UserPrivilegesValidator from "~/fc/UserPrivilegesValidator";
import ToastManager from "~/fc/ToastManager";

class FileValidationHandler {
    constructor(context, allowedList) {
        this.context = context;
        this.store = this.context.$store;
        this.allowedList = allowedList;
        this.file = null;
    }

    /**
     * @returns FileValidationHandler
     */
    setFile(file) {
        this.file = file;
        return this;
    }

    /**
     * This method validates the given file and throws a custom exception
     * if the file's extension and size are not valid according to our rules.
     * @returns Boolean
     */
    validate() {
        this.validateMergePrivilege()
        if (!this.validateName(this.file.name))
            throw new InvalidNameException(this.file);
        else if (!this.validateExt(this.file.ext, false)) // We want the user to report unsupported extensions, which is why 'strict' param is false.
            throw new InvalidExtensionException(this.file);
        else if (!this.validateSize(this.file.total))
            throw new InvalidSizeException(this.file);
        else return true;
    }

    validateMergePrivilege() {
        new UserPrivilegesValidator($nuxt).validateMergePrivilege()
    }

    validateName(fileName) {
        return fileName.length <= 255;
    }

    /**
     * Validates the file extension against the allowed extensions.
     * @param {*} ext
     * @param {*} strict
     * @returns Boolean
     */
    validateExt(ext, strict = true) {
        if (!ext && strict) return false;
        if (!ext && !strict) {
            this.handleInvalidExtension(null, false);
        }
        if (!this.allowedList) return true;
        let extension = ext.toLowerCase();
        const convert = { iiq: "raw", tif: "tiff", jpeg: "jpg" };
        if (convert[extension]) extension = convert[extension];
        return this.allowedList.split(",").includes("." + extension);
    }

    /**
     * Validates the file size against our max size limit.
     * @param {*} size
     * @returns Boolean
     */
    validateSize(size) {
        return size < this.getUploadMaxLimit();
    }

    getUploadMaxLimit() {
        return new UserPrivilegesValidator(this.context).getUploadMaxLimit();
    }

    /**
     * Handles the custom Exceptions
     * @param {*} exception
     */
    handle(exception) {
        if (exception instanceof InvalidExtensionException) {
            this.handleInvalidExtension(exception.getFile());
        } else if (exception instanceof InvalidSizeException) {
            this.handleInvalidSize(exception.getFile());
        } else if (exception instanceof InvalidNameException) {
            this.handleInvalidName(exception.getFile());
        } else {
            this.handleDefault(exception);
        }
    }

    handleInvalidExtension(file, showPopUp = true) {
        const ext = file && file.ext ? file.ext.toLowerCase() : 'null';
        this.context.$gtm.push({ event: "missing_output", unsupported_conversion: `${ext} to null`, });
        if (showPopUp) this.store.commit("showInvalidPopup", '<span class="ext">.' + ext + "</span>");
    }

    handleInvalidName() {
        new ToastManager().add("danger", "", $nuxt.$t("char_limit_exceeded_toast"));
    }

    handleInvalidSize() {
        new UserPrivilegesValidator(this.context)
            .setFile(this.file)
            .handleMaxFileSizeExceeded(this.exception);
    }

    /**
     * If it's not an Error handled by us,
     * we simply show a toast to user and
     * log it to Sentry.
     */
    handleDefault(exception) {
        console.error('File Validation Error:', exception)
        new ToastManager(this.context).add(
            "danger",
            "Error",
            `${$nuxt.$t("something_went_wrong")} (5)`
        );

        $nuxt.$sentry.captureEvent({
            message: "Something went wrong! (5)",
            extra: {
                reason: exception?.message || JSON.stringify(exception),
                stack: exception?.stack,
            },
        });
    }

    showErrorAlert(reason) {
        this.context.$bus.$emit("alert", {
            type: "danger",
            text: `${this.exception.file.name} is invalid.` + reason,
        });
    }
}

export default FileValidationHandler;

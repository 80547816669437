export default class ValidationException extends Error {
  constructor (file) {
    super()
    this.file = file
    this.name = this.constructor.name
  }

  getFile () {
    return this.file
  }
}

import { checkIfOptionExistsForAllFiles } from "../advancedOptionHelper"
import { MERGE_OPTION_IDS } from '~/config/config'
import UserPrivilegesValidator from "../UserPrivilegesValidator"
export default class MergeOptionHandler {
    constructor() {
        this.mergeOptionId = MERGE_OPTION_IDS
        this.userPrivilegesValidator = new UserPrivilegesValidator($nuxt)
    }
    allowMerge() {
        const hasMultipleFiles = $nuxt.$store.state.list.length > 1
        const hasMergeOptionOnEachFile = this.hasMergeOptionOnEachFile()
        const { slug } = this.generateSlug()
        if (slug !==  $nuxt.$store.state.advancedSetting.merging) this.disableMerge()
        return hasMultipleFiles && hasMergeOptionOnEachFile
    }
    hasMergeOptionOnEachFile() {
        return checkIfOptionExistsForAllFiles(this.mergeOptionId[0]) || checkIfOptionExistsForAllFiles(this.mergeOptionId[1])
    }
    initiateMerge() { }
    generateSlug (single = false) {
        const { targetForFile } = $nuxt.$store.state.advancedSetting;
        const list = $nuxt.$store.state.list
        let selectedTarget = targetForFile[list[0].id]
        if (list.length) {
          const targets = list.map(({ id }) => (targetForFile[id]?.name || "").toLowerCase());
          selectedTarget = targets.every((target, i) => i === 0 || target === targets[0]) ? targets[0] || selectedTarget : selectedTarget;
        }
        if (!selectedTarget)
            selectedTarget =
              $nuxt.$store.state.info.target.ext || $nuxt.$store.state.info.target;

        selectedTarget = selectedTarget && (typeof selectedTarget === "string") ? selectedTarget : selectedTarget.ext

        let slug = single ? `${list[0].ext}-to-${selectedTarget}` : `to-${selectedTarget}`

        return {
            target: selectedTarget,
            slug: slug
        }
    }
    async enableMerge() {
        const { target, slug } = this.generateSlug()
        await $nuxt.$store.dispatch("advancedSetting/setMerge", {slug, targetExt: target})
        const merging = $nuxt.$store.state.advancedSetting.merging
        if (merging) {
            const mergeId = merging.includes("pdf")
              ? this.mergeOptionId[0]
              : this.mergeOptionId[1];

            $nuxt.$store.commit("advancedSetting/setSelectionForRoot", {
              option_id: mergeId,
              value: true,
            });
            $nuxt.$store.commit("advancedSetting/setRootSetting", true);
        }
    }
    async disableMerge() {
        const { slug } = this.generateSlug(true)
        await $nuxt.$store.dispatch("advancedSetting/disableMerge", {slug})
        $nuxt.$store.commit('advancedSetting/setSelectionForRoot', {option_id: this.mergeOptionId[0], value: false})
        $nuxt.$store.commit('advancedSetting/setSelectionForRoot', {option_id: this.mergeOptionId[1], value: false})
        $nuxt.$store.commit("advancedSetting/setRootSetting", false)
    }
    setMerge(merge) {
        merge ? this.enableMerge() : this.disableMerge()
    }
    static showMergingOption() {
        /**
         * Conditions to allow a merge conversion - 
         * 1. All files must have the same target selected and the same type.
         * 2. The selected target must be among the allowed target extensions.
         * 3. All the file extensions must be among the allowed source extensions.
         */

        const files = $nuxt.$store.state.list
        const targetForFile = $nuxt.$store.state.advancedSetting.targetForFile
        const selectedTarget = new Set(Object.values(targetForFile).map(obj => obj.ext)).size === 1 ? [...new Set(Object.values(targetForFile).map(obj => obj.ext))][0] : false;
        if(!selectedTarget) return false;

        const typeForExt = $nuxt.$store.state.advancedSetting.typeForExt
        const allFileExt = files.map(file => file.ext)
        const selectedExts = [...new Set(Object.values(allFileExt))]
        const selectedTypes = selectedExts.every(key => typeForExt.hasOwnProperty(key)) ? selectedExts.map(key => typeForExt[key]) : false
        
        if(!selectedTypes) return false;

        const mergeInfo = $nuxt.$store.state.advancedSetting.mergeInfo
                            ?.filter(info => selectedTypes.includes(info.type))
                            .reduce((acc, obj) => ({ source: [...acc.source, ...obj.source], target: obj.target }), { source: [], target: [] })

        if(!mergeInfo) return false;

        const allowedTargetExt = mergeInfo.target
        const targetMatch = allowedTargetExt.includes(selectedTarget)
        if(!targetMatch) return false;

        const allowedSourceExt = mergeInfo.source
        const selectedSourceExt = files.map(file => file.ext)
        const sourceMatch = selectedSourceExt.every(format => allowedSourceExt.includes(format));
        if(!sourceMatch) return false;
     
        return true;
    }
}
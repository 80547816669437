
export default {
    name: 'UploadFromDevice',
    props: {
        single: {
            default: true,
            required: false
        },
        allowedList: {
            default: '',
            required: false
        }
    },
    methods: {
        picked(e) {
            this.$emit('picked', e)
        }
    }
}
